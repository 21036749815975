<template>
  <v-container>
    <v-data-table
      v-model="selected"
      calculate-widths
      :headers="header_orders[$vuetify.breakpoint.xsOnly]"
      :items="docs"
      :items-per-page="15"
      item-key="index"
      sort-by="expedition_date"
      :search="search"
      sort-desc
      mobile-breakpoint="0"
      class="table-cursor elevation-2"
      loading-text="Cargando ..."
      @click:row="viewDoc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> Documentos electronicos Recibidos </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" fab dark small color="success" @click="post_receptions">
            <v-icon dark> mdi-sync </v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="pa-2">
          <v-col>
            <v-text-field
              v-model="issueDateFrom"
              label="Desde"
              hide-details="auto"
              required
              outlined
              type="date"
              @change="get_oders()"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="issueDateTo"
              label="Hasta"
              hide-details="auto"
              required
              outlined
              type="date"
              @change="get_oders()"
            />
          </v-col>
          <v-col>
            <v-text-field v-model="search" label="Buscar" class="mx-4" />
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.type_document_id`]="{ item }" class="text-right">
        {{ item.type_document_id }}
      </template>
      <template v-slot:[`item.delivery_date`]="{ item }" class="text-right">
        {{ item.delivery_date }}<br />
        <small>{{ item.delivery_time }}</small>
      </template>
      <template v-slot:[`item.total`]="{ item }" class="text-right">
        {{ "$ " + parseFloat(item.total).toLocaleString() }}
      </template>

      <template slot="body.append">
        <tr>
          <th>Totales</th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-right">
            {{ sumField(selected, "total").toLocaleString(2) }}
          </th>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="view_doc" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Documento Recibido</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab icon @click="view_doc = !view_doc">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="docSelected.issue_date"
                label="Efectuado"
                hide-details="auto"
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="docSelected.due_date"
                label="Vencimiento"
                hide-details="auto"
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="docSelected.expedition_date"
                label="Expedicion"
                hide-details="auto"
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="docSelected.date"
                label="Fecha"
                hide-details="auto"
                dense
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="docSelected.identification_number"
                label="Identificación"
                hide-details="auto"
                dense
                outlined
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="docSelected.name"
                label="Nombre"
                hide-details="auto"
                dense
                outlined
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="docSelected.subject"
                label="Identificación"
                hide-details="auto"
                dense
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="docSelected.type_document_id"
                label="Tipo Documento"
                hide-details="auto"
                dense
                outlined
                readonly
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="docSelected.number"
                label="Número"
                hide-details="auto"
                dense
                outlined
                readonly
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="docSelected.total"
                label="Total"
                hide-details="auto"
                dense
                outlined
                readonly
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="docSelected.uuid"
                label="uuid"
                hide-details="auto"
                dense
                outlined
                readonly
              />
            </v-col>
          </v-row>
          <v-form v-model="valid" ref="itemForm" @submit="item_submit">
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  v-model="doc_inbox_event.event_type"
                  hide-details="auto"
                  label="Evento"
                  :items="events_options"
                  item-text="name"
                  item-value="code"
                  outlined
                  required
                  :rules="f_required"
                />
              </v-col>
              <v-col cols="12" md="12" v-if="doc_inbox_event.event_type === '031'">
                <v-select
                  v-model="doc_inbox_event.rejection_concept_id"
                  hide-details="auto"
                  label="Concepto rechazo"
                  :items="rejection_concepts"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  :rules="f_required"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="doc_inbox_event.type_document_identification_id"
                  hide-details="auto"
                  label="Tipo ID"
                  :items="type_document_identification_id"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  dense
                  :rules="f_required"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="doc_inbox_event.identification_number"
                  label="Número"
                  hide-details="auto"
                  dense
                  outlined
                  :rules="f_required"
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  v-model="doc_inbox_event.first_name"
                  label="Nombre"
                  hide-details="auto"
                  dense
                  outlined
                  :rules="f_required"
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  v-model="doc_inbox_event.family_name"
                  label="Apellido"
                  hide-details="auto"
                  dense
                  outlined
                  :rules="f_required"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-btn color="success" type="submit"> Enviar </v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-action> </v-card-action>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, fe_ws, getToday, getIssueDate } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import docs_lst from "../../utils/docs.js";

export default {
  components: {},
  mounted() {
    this.company = this.$store.getters.company;
    //var params = this.$route.params;
    //console.log(params);
    this.get_settings();
  },
  data: () => ({
    doc_inbox_event: {
      code: null,
      event_type: null,
      number: null,
      uuid: null,
      sync: null,
      type_document_identification_id: null,
      identification_number: null,
      first_name: null,
      family_name: null,
      rejection_concept_id: null,
      issue_date: null,
    },
    valid:false,
    categories: [],
    event: null,
    doc_type: null,
    selected: [],
    docType: docs_lst().docType,
    view_doc: false,
    items: [],
    search: "",
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    docs: [],
    f_required: [(v) => !!v || "Requerido"],
    header_orders: {
      false: [
        {
          text: "Fecha Emisión",
          align: "start",
          sortable: true,
          value: "expedition_date",
          dataType: "date",
        },
        {
          text: "Vencimiento",
          align: "start",
          value: "due_date",
          dataType: "text",
        },
        
        {
          text: "Tipo Doc",
          align: "start",
          value: "type_document_id",
          dataType: "text",
        },
        {
          text: "Número",
          align: "start",
          value: "number",
          dataType: "text",
        },
        {
          text: "Nombre",
          align: "start",
          value: "name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
      true: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
    },
    rejection_concepts: [],
    type_documents: [],
    issueDateFrom: getIssueDate(60),
    issueDateTo: getToday(),
    docSelected: {
      date: null,
      due_date: null,
      expedition_date: null,
      identification_number: null,
      is_valid: null,
      issue_date: null,
      name: null,
      number: null,
      receiver_identification_number: null,
      receiver_name: null,
      scope: null,
      status_message: null,
      subject: null,
      total: null,
      type_document_id: null,
      type_environment_id: null,
      uuid: null,
    },
    events_options: [
      { code: "030", name: "Acuse de Recibido Factura Electrónica de Venta" },
      { code: "031", name: "Reclamo de la Factura Electrónica de Venta" },
      { code: "032", name: "Recibo del bien y/o prestación servicio" },
      { code: "033", name: "Aceptación Expresa" },
      { code: "034", name: "Aceptación Tásita" },
    ],

    type_document_identification_id: [],
  }),
  methods: {
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    get_settings() {
      var qry = {
        account: this.$store.state.profile.account,
        store: this.$store.getters.company.code,
        table: "settings",
        filters: [
          {
            field: "tableName",
            operator: " IN ",
            value:
              "'rejection_concepts','type_documents','type_document_identifications'",
          },
        ],
      };
      this.$store.dispatch("setloading", true);
      
      fe_ws("get_table", qry, (data) => {
        this.$store.dispatch("setloading", false);
        
        var ctgry = data.reduce(function (r, a) {
          r[a.tableName] = r[a.tableName] || [];
          r[a.tableName].push(a);
          return r;
        }, Object.create(null));
        console.log(ctgry["rejection_concepts"]);
        this.rejection_concepts = ctgry["rejection_concepts"];
        this.type_documents = ctgry["type_documents"];
        this.type_document_identification_id = ctgry["type_document_identifications"];
        this.get_docs();
      });
    },
    get_docs() {
      var qry = {
        table: "docs_inbox",
        filters: [
          {
            field: "receiver_identification_number",
            operator: "=",
            value: this.$store.state.company.identification_id,
          },
        ],
      };
      this.$store.dispatch("setloading", true);
      console.log(qry);
      webserver("get_dataTable", qry, (data) => {
        this.$store.dispatch("setloading", false);
        console.log(data);
        this.docs = data;
      });
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.doc_inbox_event.uuid = this.docSelected.uuid;
        var qry = {
            account: this.$store.state.profile.account,
            table: "docs_inbox_events",
            store: this.$store.getters.company.code,
            data: [this.doc_inbox_event],
          };
        console.log(this.doc_inbox_event);
         webserver("put_table", qry, (data) => {
            console.log(data);
          });
      }
    },
    post_receptions() {
      var qry = {
        method: "POST",
        nit: this.$store.getters.company.identification_id.replace("-", ""),
        store: this.company.code,
      };
      this.loading_status = true;
      fe_ws("post_receptions", qry, () => {
        this.loading_status = false;
        this.get_docs();
      });
    },
    post_event() {
      var data = {
        number: 1,
        uuid:
          "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        sync: true,
        rejection_concept_id: 1,
        person: {
          type_document_identification_id: 3,
          identification_number: 123456789,
          first_name: "XXXX",
          family_name: "XXXXXXX",
        },
      };
      var qry = {
        method: "POST",
        nit: this.$store.getters.company.identification_id.replace("-", ""),
        store: this.company.code,
        data: data,
      };
      this.loading_status = true;
      fe_ws("post_receptions", qry, () => {
        this.loading_status = false;
        this.get_docs();
      });
    },
    viewDoc(e) {
      console.log(e);
      this.docSelected = { ...e };
      this.view_doc = true;
    },
  },

  watch: {},
};
</script>

<style></style>
